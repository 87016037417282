<template>
    <div class="medialist">
        <a-page-header
            :title="$t('inbound.add.titel')"
            class="header_title"
        />
        <a-spin :spinning="spinning">
        <div class="box_content">
            <a-tabs default-active-key="1" size="large">
                <a-tab-pane key="1" :tab="$t('inbound.add.tab_1')">
                    <a-form :form="form">
                    <table>
                        <tr>
                            <td style="padding-right: 15px;">
                                <a-input-group compact>
                                    <a-input :placeholder="$t('inbound.add.item_1')" style="width:70%" @change="handleChange_0"/>
                                    <a-button class="ant-btn-danger" @click="mKPOrderIDBtn"  type="primary"  style="width:30%">
                                        {{$t('inbound.add.import')}}
                                    </a-button>
                                </a-input-group>
                            </td>
                            <td><span class='inbound_tip'>{{$t('inbound.add.import2')}}</span></td>
                        </tr>
                    </table>
                    <div class="div-hr">&nbsp;</div>
                    <table>
                        <tr>
                            <td class="table_td_title_1">{{$t('inbound.add.item_2')}}:</td><td class="table_td_input_1"><a-input placeholder="" :value="form_values.field_1" name='field_1' @change="handleChange_1"/></td>
                            <td class="table_td_title_2">{{$t('inbound.add.item_3')}}:</td>
                            <td class="table_td_input_2">
                                <a-input-group compact>
                                    <a-input placeholder="" style="width: 70%" name='field_2' @change="handleChange_2"/>
                                    <a-button class="ant-btn-danger" @click="skuBtn" type="primary" style="width: 30%">
                                        {{$t('inbound.add.import')}}
                                    </a-button>
                                </a-input-group>
                            </td>
                            <td class="table_td_title_3">{{$t('inbound.add.item_4')}}:</td><td class="table_td_input_3"><a-input placeholder=""  :value="form_values.field_3" name='field_3' @change="handleChange_3"/></td>
                        </tr>
                    </table>
                    <div class="div-hr">&nbsp;</div>
                    <table class="table_form">
                        <tr>
                            <td class="table_td_title_1">{{$t('inbound.add.item_5')}}:</td><td class="table_td_input_1"><a-input placeholder=""  :value="form_values.field_4" @change="handleChange_4"/></td>
                            <td class="table_td_title_2">{{$t('inbound.add.item_6')}}:</td><td class="table_td_input_2"><a-input placeholder="" :value="form_values.field_5" @change="handleChange_5"/></td>
                        </tr>
                        <tr>
                            <td class="table_td_title_1">{{$t('inbound.add.item_7')}}:</td><td class="table_td_input_1"><a-input placeholder=""  :value="form_values.field_6" @change="handleChange_6"/></td>
                            <td class="table_td_title_2">{{$t('inbound.add.item_8')}}:</td><td class="table_td_input_2"><a-input placeholder=""  :value="form_values.field_7" @change="handleChange_7"/></td>
                        </tr>
                        <tr>
                            <td class="table_td_title_1">{{$t('inbound.add.item_9')}}:</td><td class="table_td_input_1"><a-input placeholder="" :value="form_values.field_8"  @change="handleChange_8"/></td>
                            <!-- <td class="table_td_title_2">State/county:</td><td class="table_td_input_2"><a-input placeholder=""  :value="form_values.field_9" @change="handleChange_9"/></td> -->
                            <td class="table_td_title_2">{{$t('inbound.add.item_10')}}:</td>
                            <td class="table_td_input_2">
                                <a-select style="width: 100%" default-value="0" :value="form_values.field_9" @change="handleChange_9">
                                    <a-select-option value="0">{{$t('inbound.add.select_defautl')}}</a-select-option>
                                    <a-select-option value="AA">AA</a-select-option>
                                    <a-select-option value="AE">AE</a-select-option>
                                    <a-select-option value="AK">AK</a-select-option>
                                    <a-select-option value="AL">AL</a-select-option>
                                    <a-select-option value="AP">AP</a-select-option>
                                    <a-select-option value="AR">AR</a-select-option>
                                    <a-select-option value="AS">AS</a-select-option>
                                    <a-select-option value="AZ">AZ</a-select-option>
                                    <a-select-option value="CA">CA</a-select-option>
                                    <a-select-option value="CO">CO</a-select-option>
                                    <a-select-option value="CT">CT</a-select-option>
                                    <a-select-option value="DC">DC</a-select-option>
                                    <a-select-option value="DE">DE</a-select-option>
                                    <a-select-option value="FL">FL</a-select-option>
                                    <a-select-option value="FM">FM</a-select-option>
                                    <a-select-option value="GA">GA</a-select-option>
                                    <a-select-option value="GU">GU</a-select-option>
                                    <a-select-option value="HI">HI</a-select-option>
                                    <a-select-option value="IA">IA</a-select-option>
                                    <a-select-option value="ID">ID</a-select-option>
                                    <a-select-option value="IL">IL</a-select-option>
                                    <a-select-option value="IN">IN</a-select-option>
                                    <a-select-option value="KS">KS</a-select-option>
                                    <a-select-option value="KY">KY</a-select-option>
                                    <a-select-option value="LA">LA</a-select-option>
                                    <a-select-option value="MA">MA</a-select-option>
                                    <a-select-option value="MD">MD</a-select-option>
                                    <a-select-option value="ME">ME</a-select-option>
                                    <a-select-option value="MH">MH</a-select-option>
                                    <a-select-option value="MI">MI</a-select-option>
                                    <a-select-option value="MN">MN</a-select-option>
                                    <a-select-option value="MO">MO</a-select-option>
                                    <a-select-option value="MP">MP</a-select-option>
                                    <a-select-option value="MS">MS</a-select-option>
                                    <a-select-option value="MT">MT</a-select-option>
                                    <a-select-option value="NC">NC</a-select-option>
                                    <a-select-option value="ND">ND</a-select-option>
                                    <a-select-option value="NE">NE</a-select-option>
                                    <a-select-option value="NH">NH</a-select-option>
                                    <a-select-option value="NJ">NJ</a-select-option>
                                    <a-select-option value="NM">NM</a-select-option>
                                    <a-select-option value="NV">NV</a-select-option>
                                    <a-select-option value="NY">NY</a-select-option>
                                    <a-select-option value="OH">OH</a-select-option>
                                    <a-select-option value="OK">OK</a-select-option>
                                    <a-select-option value="OR">OR</a-select-option>
                                    <a-select-option value="PA">PA</a-select-option>
                                    <a-select-option value="PR">PR</a-select-option>
                                    <a-select-option value="PW">PW</a-select-option>
                                    <a-select-option value="RI">RI</a-select-option>
                                    <a-select-option value="SC">SC</a-select-option>
                                    <a-select-option value="SD">SD</a-select-option>
                                    <a-select-option value="TN">TN</a-select-option>
                                    <a-select-option value="TX">TX</a-select-option>
                                    <a-select-option value="UT">UT</a-select-option>
                                    <a-select-option value="VA">VA</a-select-option>
                                    <a-select-option value="VI">VI</a-select-option>
                                    <a-select-option value="VT">VT</a-select-option>
                                    <a-select-option value="WA">WA</a-select-option>
                                    <a-select-option value="WI">WI</a-select-option>
                                    <a-select-option value="WV">WV</a-select-option>
                                    <a-select-option value="WY">WY</a-select-option>
                                </a-select>
                            </td>
                        </tr>
                        <tr>
                            <td class="table_td_title_1">{{$t('inbound.add.item_11')}}:</td>
                            <td class="table_td_input_1">
                                <a-select style="width: 100%" default-value="0" :value="form_values.field_10" @change="handleChange_10">
                                    <a-select-option value="0">{{$t('inbound.add.select_defautl')}}</a-select-option>
                                    <a-select-option value="US">United States</a-select-option>
                                </a-select>
                            </td>
                            
                            <td class="table_td_title_2">{{$t('inbound.add.item_12')}}:</td><td class="table_td_input_2"><a-input placeholder="" :value="form_values.field_11" @change="handleChange_11"/></td>
                        </tr>
                    </table>
                    <div class="div-hr">&nbsp;</div>
                    <table class="table_form">
                        <tr>
                            <td class="table_td_title_1-4" style="padding:0;">* {{$t('inbound.add.item_13')}}:</td><td class="table_td_input_1-4"><a-input placeholder="" suffix="kg" :value="form_values.field_12" @change="handleChange_12"/></td>
                            <td class="table_td_title_1-4">* {{$t('inbound.add.item_14')}}:</td><td class="table_td_input_1-4"><a-input placeholder="" suffix="cm" :value="form_values.field_13" @change="handleChange_13"/></td>
                            <td class="table_td_title_1-4">* {{$t('inbound.add.item_15')}}:</td><td class="table_td_input_1-4"><a-input placeholder="" suffix="cm" :value="form_values.field_14" @change="handleChange_14"/></td>
                            <td class="table_td_title_1-4">* {{$t('inbound.add.item_16')}}:</td><td class="table_td_input_1-4"><a-input placeholder="" suffix="cm" :value="form_values.field_15" @change="handleChange_15"/></td>
                        </tr>
                        <tr>
                            <td class="table_td_title_1">* {{$t('inbound.add.item_17')}}:</td><td colspan="2">
                                <a-select style="width: 100%" default-value="1" :value="form_values.field_16" @change="handleChange_16">
                                    <a-select-option value="0">{{$t('inbound.add.select_defautl')}}</a-select-option>
                                    <a-select-option value="1">Gyroor</a-select-option>
                                    <a-select-option value="2">DataHunt</a-select-option>
                                    <!-- <a-select-option value="3">Return Helper</a-select-option> -->
                                    <!-- <a-select-option value="4">SZ Warehouse</a-select-option> -->
                                    <a-select-option value="5">Microsel</a-select-option>
                                </a-select>
                            </td>
                            <td class="table_td_title_2">{{$t('inbound.add.item_18')}}:</td><td colspan="2">
                                <a-select style="width: 100%" default-value="0" :value="form_values.field_17" @change="handleChange_17">
                                    <a-select-option value="0">{{$t('inbound.add.select_defautl')}}</a-select-option>
                                    <a-select-option value="1">UPS</a-select-option>
                                    <a-select-option value="2">USPS</a-select-option>
                                    <a-select-option value="3">DHL</a-select-option>
                                    <a-select-option value="4">Fedex</a-select-option>
                                </a-select>
                            </td>
                        </tr>
                        <tr>
                            <td class="table_td_title_1">{{$t('inbound.add.item_19')}}:</td><td colspan='2'><a-input placeholder="" :value="form_values.field_18" @change="handleChange_18"/></td>
                        </tr>
                         <tr>
                            <td class="table_td_title_1">{{$t('inbound.add.item_20')}}:</td><td colspan='2'><a-textarea placeholder="" :value="form_values.field_19" @change="handleChange_19"/></td>
                        </tr>
                    </table>
                    </a-form>
                    <div class="div-hr">&nbsp;</div>
                    <table class="table_form">
                        <tr>
                            <td>
                                <a-button class="ant-btn-danger" @click="createOrderBtn"  type="primary">
                                    {{$t('inbound.add.item_21')}}
                                </a-button>
                            </td>
                            <td style="padding-left:15px;">
                                <a-button class="ant-btn-danger" @click="purchaseShipmentLabelBtn"  type="primary">
                                    {{$t('inbound.add.item_22')}}
                                </a-button>
                            </td>
                        </tr>
                    </table>
                </a-tab-pane>
                <a-tab-pane key="2" :tab="$t('inbound.add.tab_2')" force-render>
                    
                </a-tab-pane>
            </a-tabs>
        </div>
        </a-spin>
    </div>
</template>

<script>
import {add,import_mkp_order_id,import_sku} from '@/network/inbound';
export default {
  name: 'NodeprojectMedia',
  components: {  },
  directives: {  },
  data() {
    return {
        form: this.$form.createForm(this, { name: 'coordinated' }),
        form_values:{
            field_0:'',
            field_1:'',
            field_2:'',
            field_3:'',
            field_4:'',
            field_5:'',
            field_6:'',
            field_7:'',
            field_8:'',
            field_9:'0',
            field_10:'0',
            field_11_0:'0',
            field_11:'',
            field_12:'',
            field_13:'',
            field_14:'',
            field_15:'',
            field_16:'0',
            field_17:'0',
            field_18:'',
            purchase_shipment_label:0,
        },
        spinning:false,
        // form: this.$form.createForm(this, { name: 'coordinated' }),
    };
  },
  mounted() {
    
  },
  methods: {
    createOrderBtn(){
        this.spinning = true;
        console.log(this.form_values);
        add(this.form_values).then((res) => {
            this.spinning = false;
            if(res.code == 0){
                this.form_values ={
                    field_0:'',
                    field_1:'',
                    field_2:'',
                    field_3:'',
                    field_4:'',
                    field_5:'',
                    field_6:'',
                    field_7:'',
                    field_8:'',
                    field_9:'0',
                    field_10:'0',
                    field_11_0:'0',
                    field_11:'',
                    field_12:'',
                    field_13:'',
                    field_14:'',
                    field_15:'',
                    field_16:0,
                    field_17:0,
                    field_18:'',
                    purchase_shipment_label:0,
                };
                this.$message.success(res.msg,1).then(()=>{
                    this.$success({
                        title: 'success',
                        content: (
                            <div>
                                <a href="https://ship13.shipstation.com/login?firsttime=true" target="_blank">Click Login ShipStation</a>
                            </div>
                        ),
                    });
                });
            }else{
                this.$message.error(res.msg)
            }
        });
    },
    purchaseShipmentLabelBtn(){
        this.spinning = true;
        this.form_values.purchase_shipment_label = 1;
        console.log(this.form_values);
        add(this.form_values).then((res) => {
            this.spinning = false;
            if(res.code == 0){
                this.form_values ={
                    field_0:'',
                    field_1:'',
                    field_2:'',
                    field_3:'',
                    field_4:'',
                    field_5:'',
                    field_6:'',
                    field_7:'',
                    field_8:'',
                    field_9:'0',
                    field_10:0,
                    field_11_0:'0',
                    field_11:'',
                    field_12:'',
                    field_13:'',
                    field_14:'',
                    field_15:'',
                    field_16:0,
                    field_17:0,
                    field_18:'',
                    field_19:'',
                    purchase_shipment_label:0,
                };
                // this.$message.success(res.msg).then(()=>{
                //     this.$success({
                //         title: 'This is a success message',
                //         content: (
                //         <div>
                //             <p>some messages...some messages...</p>
                //         </div>
                //         ),
                //     });
                // });
            }else{
                this.$message.error(res.msg);
            }
        });
    },
    mKPOrderIDBtn(){
        let mkp_order_id = this.form_values.field_0;
        if(mkp_order_id){
           this.spinning = true;
          import_mkp_order_id(mkp_order_id).then((res) => {
            this.spinning = false;
            if(res.code == 0){
                this.form_values.field_4 = res.data.field_4;
                this.form_values.field_5 = res.data.field_5;
                this.form_values.field_6 = res.data.field_6;
                this.form_values.field_7 = res.data.field_7;
                this.form_values.field_8 = res.data.field_8;
                this.form_values.field_9 = res.data.field_9;
                this.form_values.field_10 = res.data.field_10;
                this.form_values.field_11 = res.data.field_11;
            }
            this.spinning = false;
          });
        }
    },
    skuBtn(){
        let sku = this.form_values.field_2;
        if(sku){
            this.spinning = true;
            import_sku(sku).then((res) => {
            this.spinning = false;
            if(res.code == 0){
                this.form_values.field_12 = res.data.field_12;
                this.form_values.field_13 = res.data.field_13;
                this.form_values.field_14 = res.data.field_14;
                this.form_values.field_15 = res.data.field_15;
            }
          });
        }
    },
    handleChange_0(k){
        console.log(k);
        this.form_values.field_0 = k.target.value;
    },
    handleChange_1(k){
        console.log(k);
        this.form_values.field_1 = k.target.value;
    },handleChange_2(k){
        console.log(k.target.value);
        this.form_values.field_2 = k.target.value;
    },handleChange_3(k){
        console.log(k.target.value);
        this.form_values.field_3 = k.target.value;
    },handleChange_4(k){
        console.log(k.target.value);
        this.form_values.field_4 = k.target.value;
    },handleChange_5(k){
        console.log(k.target.value);
        this.form_values.field_5 = k.target.value;
    },handleChange_6(k){
        console.log(k.target.value);
        this.form_values.field_6 = k.target.value;
    },handleChange_7(k){
        console.log(k.target.value);
        this.form_values.field_7 = k.target.value;
    },handleChange_8(k){
        console.log(k.target.value);
        this.form_values.field_8 = k.target.value;
    },handleChange_9(k){
        console.log(k);
        this.form_values.field_9 = k;
    },handleChange_10(k){
        console.log(k);
        this.form_values.field_10 = k;
    },handleChange_11_0(k){
        console.log(k);
        this.form_values.field_11_0 = k;
    },handleChange_11(k){
        console.log(k.target.value);
        this.form_values.field_11 = k.target.value;
    },handleChange_12(k){
        console.log(k.target.value);
        this.form_values.field_12 = k.target.value;
    },handleChange_13(k){
        console.log(k.target.value);
        this.form_values.field_13 = k.target.value;
    },handleChange_14(k){
        console.log(k.target.value);
        this.form_values.field_14 = k.target.value;
    },handleChange_15(k){
        console.log(k);
        this.form_values.field_15 = k.target.value;
    },handleChange_16(k){
        console.log(k);
        this.form_values.field_16 = k;
    },handleChange_17(k){
        console.log(k);
        this.form_values.field_17 = k;
    },handleChange_18(k){
        console.log(k.target.value);
        this.form_values.field_18 = k.target.value;
    },
    handleChange_19(k){
        console.log(k.target.value);
        this.form_values.field_19 = k.target.value;
    },
  },
};
</script>

<style  scoped>
    .div-hr{
        height:30px;
        width: 100%;
    }
    .table_td_title_1{
        width: 117px;
    }
    .table_td_title_2{
        width: 115px;
        padding-left: 15px;
    }
    .table_td_title_3{
        width: 100px;
        padding-left: 15px;
    }
    .table_td_input_1,.table_td_input_2{
        width: 250px;
    }
    .table_td_input_3{
         width: 100px;
    }
    .table_td_title_1-4{
        width: 117px;
        padding-left: 15px;
    }
    .table_td_input_1-4{
        width: 150px;
    }
    .table_form tr td{
        padding-bottom: 5px;
    }
    .inbound_tip{
        font-family: "Arial Normal", "Arial", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        letter-spacing: normal;
        line-height: normal;
        text-align: left;
        color: #7F7F7F;
        word-wrap: break-word;
        text-transform: none;
        text-rendering: optimizeLegibility;
        -webkit-font-feature-settings: "kern";
        font-kerning: normal;
    }
</style>